import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00000', // Beige
    },
    secondary: {
      main: '#D5C4A1', // Black
    },
    background: {
      default: '#F5ECE3', // Light nude for background
    },
    text: {
      primary: '#000000', // Black text
      secondary: '#D5C4A1', // Dark nude text
    },
  },
  typography: {
    fontFamily: [
      'Playfair Display', 
      'sans-serif',
    ].join(','),
  },
});

export default theme;